import { appConfig } from "./AppConfig";
export class FunctionAuth {
  authMap;
  constructor() {
    this.authMap = new Map();
    const COM_N_001_Map = new Map();
    COM_N_001_Map.set(appConfig.FUNCTION_ID.P_MNG_002_READ, true);
    COM_N_001_Map.set(appConfig.FUNCTION_ID.P_MNG_002_UPDATE, true);
    this.authMap.set(appConfig.AUTH_GROUP_ID.COM_N_001, COM_N_001_Map);
    const COM_N_002_Map = new Map();
    COM_N_002_Map.set(appConfig.FUNCTION_ID.P_MNG_002_READ, true);
    COM_N_002_Map.set(appConfig.FUNCTION_ID.P_MNG_002_UPDATE, false);
    this.authMap.set(appConfig.AUTH_GROUP_ID.COM_N_002, COM_N_002_Map);
    const COM_O_001_Map = new Map();
    COM_O_001_Map.set(appConfig.FUNCTION_ID.P_MNG_002_READ, true);
    COM_O_001_Map.set(appConfig.FUNCTION_ID.P_MNG_002_UPDATE, false);
    this.authMap.set(appConfig.AUTH_GROUP_ID.COM_O_001, COM_O_001_Map);
    const COM_O_101_Map = new Map();
    COM_O_101_Map.set(appConfig.FUNCTION_ID.P_MNG_002_READ, true);
    COM_O_101_Map.set(appConfig.FUNCTION_ID.P_MNG_002_UPDATE, false);
    this.authMap.set(appConfig.AUTH_GROUP_ID.COM_O_101, COM_O_101_Map);
  }

  /**
   * 指定したGroupの機能権限のMapを返却します。
   * 存在しない場合は空のMapを返します。
   * @param {String} authGroupId appConfig.AUTH_GROUP_ID
   * @returns {Map}
   */
  getAllAuth(authGroupId) {
    const authGroup = this.authMap.get(authGroupId);
    return authGroup ? authGroup : new Map();
  }

  /**
   * 指定したGroup、functionIDの画面使用可否を返却します。
   * 存在しない場合もFalseを返します。
   * @param {String} authGroupId appConfig.AUTH_GROUP_ID
   * @param {String} functionId appConfig.FUNCTION_ID
   * @returns {Boolean}
   */
  canFunctionAuth(authGroupId, functionId) {
    const authGroup = this.getAllAuth(authGroupId);
    const auth = authGroup.get(functionId);
    return auth ? auth : false;
  }
}

export const functionAuth = new FunctionAuth();
