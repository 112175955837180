<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-container fluid id="sub-bar">
      <v-row>
        <v-col sm="2"><div id="lbl-screen-name">進捗確認</div></v-col>
        <v-col sm="9"></v-col>
        <v-col sm="1" class="right">
          <v-btn id="btn-close" @click="close"><img src="../assets/img/close.png" /></v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid>
      <v-row>
        <v-col sm="8">
          <v-row>
            <v-col sm="1">
              <div class="search-label">営業所</div>
            </v-col>
            <v-col sm="4">
              <v-text-field
                id="txt-sales-office"
                class="txt-single"
                v-model="salesOffice"
                readonly
              ></v-text-field>
            </v-col>
            <v-col sm="2">
              <div class="search-label">搬入日時</div>
            </v-col>
            <v-col sm="4">
              <v-text-field
                id="txt-delivery-date-time"
                class="txt-single"
                v-model="deliveryDateTime"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col sm="6">
          <v-card id="display-type" outlined>
            <v-card-title id="lbl-display-switching">表示切替</v-card-title>
            <v-btn-toggle
              v-model="operationType"
              color="blue darken-2"
              class="toggle black--text"
              @change="changeToggle"
            >
              <v-btn id="btn-all-case" value="0" class="btn"> 全件 </v-btn>
              <v-btn id="btn-not-implemented" value="1" class="btn"> 未実施 </v-btn>
              <v-btn id="btn-pack-already" value="2" class="btn"> 積付済 </v-btn>
              <v-btn id="btn-load-already" value="3" class="btn"> 積込済 </v-btn>
            </v-btn-toggle>
          </v-card>
        </v-col>
        <v-col sm="2"></v-col>
        <v-col sm="4">
          <div id="table-guide">
            <v-row>
              <v-col sm="4">
                <v-btn id="btn-update" class="api-btn" @click="update">更新</v-btn>
              </v-col>
              <v-col sm="8" id="guide-color" class="guide-space">
                <div id="lbl-warning" class="guide-error">遅延</div>
                <div id="lbl-delay" class="guide-warning">警告</div>
                <div id="lbl-diff" class="guide-diff">差分あり</div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid>
      <v-data-table
        id="detail-table"
        fixed-header
        :headers="headerItems"
        :items="progressDetailsList"
        disable-pagination
        :hide-default-footer="true"
        height="500px"
      >
        <template v-slot:item="{ item: row, index }">
          <tr :key="index" :class="row.status">
            <td :class="headerItems[0].align">
              <v-checkbox hide-details="true" class="check-box" v-model="row.check"></v-checkbox>
            </td>
            <td class="left">{{ row.deliveryAddressCode }}</td>
            <td :class="headerItems[2].align">{{ row.suppliers }}</td>
            <td :class="headerItems[3].align">{{ row.planCode }}</td>
            <td :class="headerItems[4].align">{{ row.partsNo }}</td>
            <td :class="headerItems[5].align">{{ row.partsColorNo }}</td>
            <td :class="headerItems[6].align">{{ row.issueNumber }}</td>
            <td :class="headerItems[7].align">{{ row.instructionNumber }}</td>
            <td :class="headerItems[8].align">{{ row.storageNumber }}</td>
            <td :class="headerItems[9].align">{{ row.planQuantity }}</td>
            <td :class="headerItems[10].align">{{ row.packAchivementQuantity }}</td>
            <td :class="headerItems[11].align">{{ row.loadAchivementQuantity }}</td>
            <td :class="headerItems[12].align">{{ row.packAlready }}</td>
            <td :class="headerItems[13].align">{{ row.loadAlready }}</td>
            <td :class="headerItems[14].align">{{ row.packDeadline }}</td>
            <td :class="headerItems[15].align">{{ row.loadDeadline }}</td>
            <td :class="headerItems[16].align">
              <v-tooltip bottom open-delay="500">
                <template v-slot:activator="{ on, attrs }">
                  <img
                    class="detail-icon"
                    src="../assets/img/detail.png"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <div>
                  積付作業者<br />
                  &nbsp;&nbsp;{{ row.packUserName }}<br />
                  積込作業者<br />
                  &nbsp;&nbsp;{{ row.loadUserName }}
                </div>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>

      <v-row id="footer-navi">
        <v-col sm="3">
          <v-btn
            id="btn-pack-completion"
            class="btn"
            @click="isOpenPackCompletion = true"
            :disabled="!(isDataTableChecked && canUpdateAuth && isBaseCodeChecked)"
          >
            積付完了済
          </v-btn>
          <ConfirmDialog
            :isShow.sync="isOpenPackCompletion"
            :message="message['P-MNG-002_001_C']"
            :okAction="packCompletion"
          />
          <v-btn
            id="btn-load-completion"
            class="btn"
            @click="isOpenLoadCompletion = true"
            :disabled="!(isDataTableChecked && canUpdateAuth && isBaseCodeChecked)"
          >
            積込完了済
          </v-btn>
          <ConfirmDialog
            :isShow.sync="isOpenLoadCompletion"
            :message="message['P-MNG-002_003_C']"
            :okAction="loadCompletion"
          />
        </v-col>
        <v-col sm="6">
          <v-row>
            <v-col sm="2">
              <div class="search-label">期限時間</div>
            </v-col>
            <v-col sm="4">
              <v-form ref="deadlineTime" v-model="validationResult">
                <v-text-field
                  id="txt-deadline-time"
                  class="txt-single"
                  type="number"
                  v-model.number="deadlineTime"
                  :rules="[rules.deadlineTime]"
                  @input="formValidation"
                ></v-text-field>
              </v-form>
            </v-col>
            <v-col sm="2">
              <v-btn
                id="btn-pack-deadline-update"
                class="btn"
                @click="isOpenPackDeadlineUpdate = true"
                :disabled="
                  !(validationResult && isDataTableChecked && canUpdateAuth && isBaseCodeChecked)
                "
              >
                積付期限更新
              </v-btn>
              <ConfirmDialog
                :isShow.sync="isOpenPackDeadlineUpdate"
                :message="message['P-MNG-002_004_C']"
                :okAction="packDeadlineUpdate"
              />
            </v-col>
            <v-col sm="2">
              <v-btn
                id="btn-load-deadline-update"
                class="btn"
                @click="isOpenLoadDeadlineUpdate = true"
                :disabled="
                  !(validationResult && isDataTableChecked && canUpdateAuth && isBaseCodeChecked)
                "
              >
                積込期限更新
              </v-btn>
              <ConfirmDialog
                :isShow.sync="isOpenLoadDeadlineUpdate"
                :message="message['P-MNG-002_004_C']"
                :okAction="loadDeadlineUpdate"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <SimpleDialog
      :isShow.sync="infoDialog.isOpen"
      :title="infoDialog.title"
      :message="infoDialog.message"
    />
  </div>
</template>

<script>
import Loading from "@/components/loading";
import ConfirmDialog from "@/components/ConfirmDialog";
import SimpleDialog from "@/components/SimpleDialog";

import { appConfig } from "../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../assets/scripts/js/DateTimeHelper";
import { messsageUtil } from "../assets/scripts/js/MesssageUtil";
import { functionAuth } from "../assets/scripts/js/FunctionAuth";
import { screenAuth } from "../assets/scripts/js/ScreenAuth";
export default {
  name: "progress_detail",
  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    loadingCounter: 0,
    roles: "",
    salesOffice: "",
    deliveryDateStr: "",
    deliveryTime: "",
    operationType: "",
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
    },
    isOpenPackCompletion: false,
    isOpenLoadCompletion: false,
    isOpenPackDeadlineUpdate: false,
    isOpenLoadDeadlineUpdate: false,
    deadlineTime: "",
    validationResult: false,
    message: {
      "P-MNG-002_001_C": messsageUtil.getMessage("P-MNG-002_001_C"),
      "P-MNG-002_003_C": messsageUtil.getMessage("P-MNG-002_003_C"),
      "P-MNG-002_004_C": messsageUtil.getMessage("P-MNG-002_004_C"),
    },

    headerItems: [
      { text: "", value: "check", width: "4rem", align: "left", sortable: false },
      { text: "搬入場所", value: "deliveryAddressCode", align: "left", sortable: false },
      { text: "取引先", value: "suppliers", width: "12rem", align: "center", sortable: false },
      { text: "計画コード", value: "planCode", width: "7rem", align: "left" },
      { text: "部品番号", value: "partsNo", width: "12rem", align: "center" },
      { text: "部品色番", value: "partsColorNo", width: "7rem", align: "center" },
      { text: "発行番号", value: "issueNumber", width: "9rem", align: "center" },
      { text: "指示数", value: "instructionNumber", width: "5rem", align: "right" },
      { text: "収容数", value: "storageNumber", width: "5rem", align: "right" },
      { text: "計画 ", value: "planQuantity", width: "5rem", align: "right" },
      { text: "積付実績", value: "packAchivementQuantity", width: "6rem", align: "right" },
      { text: "積込実績", value: "loadAchivementQuantity", width: "6rem", align: "right" },
      { text: "積付済", value: "packAlready", width: "4rem", align: "center", sortable: false },
      { text: "積込済", value: "loadAlready", width: "4rem", align: "center", sortable: false },
      { text: "積付期限", value: "packDeadline", align: "center" },
      { text: "積込期限", value: "loadDeadline", align: "center" },
      {
        text: "作業詳細",
        value: "iconWorkDetail",
        width: "4.5rem",
        align: "center",
        sortable: false,
      },
    ],
    progressDetailsList: [],
    rules: {
      deadlineTime: (value) => {
        return (
          (Number.isInteger(value) && value >= 0 && value <= 999) || "0～999の数値のみ可能です。"
        );
      },
    },
  }),
  methods: {
    /**
     * 初期起動：画面を開いた際に渡された値を設定します。
     */
    init() {
      screenAuth.canAccess();
      // 画面の初期値を設定します。
      this.operationType = appConfig.DETAIL_OPERATION_TYPE.all;
      this.salesOffice = this.entity.salesOffice.text;
      this.selectColumn = this.entity.selectColumn;
      this.deliveryDateStr = this.entity.deliveryDateStr;
      this.deliveryTime = this.entity.deliveryTime;
      this.searchSelect = this.entity.searchSelect;
      // 進捗情報詳細の取得プロセスを作成します。
      const processProgressDetail = this.getProgressDetailList();
      Promise.all([processProgressDetail])
        .then(() => {})
        .catch((ex) => {
          console.error("init error", ex);
        })
        .finally(() => {});
    },
    close() {
      this.$emit("update:isOpenDetailDialog", false);
    },
    /**
     * 進捗状況詳細参照APIを呼び出してテーブルに設定します。
     */
    getProgressDetailList() {
      console.debug("getProgressDetailList--start--");
      screenAuth.canAccess();
      // 進捗状況詳細参照API
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.operationType = this.operationType;
      config.params.salesOfficeCode = this.entity.selectSalesOfficeCode;
      config.params.deliveryDate = this.deliveryDateStr.replace(/-/g, "/");
      if (this.deliveryTime.length == 4) {
        config.params.deliveryHour = "0" + this.deliveryTime.substring(0, 1);
      } else {
        config.params.deliveryHour = this.deliveryTime.substring(0, 2);
      }
      config.params.supplierCode = this.entity.supplierCode;
      // config.params.deliveryAddressCode = this.entity.deliveryAddressCode;
      config.params.deliveryDestAddressCode = this.entity.deliveryDestAddressCode;

      this.loadingCounter++;
      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.progressDetail, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == "000") {
              // 項目名が違うものについては現状を保持しつつ、新たなキーとして追加します。
              this.progressDetailsList = jsonData.resIdv.resProgressDetailsList.map((row) => {
                const result = row;
                result.check = false;
                result.suppliers = row.supplierNameAlt;
                result.issueNumber = row.deliverySlipNo;
                result.instructionNumber = row.partsQty;
                result.storageNumber = row.containerCapacityQty;
                result.planQuantity = row.packPlanContainerQty;
                result.packAchivementQuantity = row.packActualContainerQty;
                result.loadAchivementQuantity = row.loadActualContainerQty;
                result.packAlready = row.packStatus == 0 ? "〇" : "×";
                result.loadAlready = row.loadStatus == 0 ? "〇" : "×";
                result.packDeadline = dateTimeHelper.toStringDate(
                  "YYYY/MM/DD hh:mm",
                  new Date(row.packLimitDatetime)
                );
                result.loadDeadline = dateTimeHelper.toStringDate(
                  "YYYY/MM/DD hh:mm",
                  new Date(row.loadLimitDatetime)
                );
                if (
                  row.packStatus == appConfig.DETAIL_PACK_STATUS.incomplete ||
                  row.loadStatus == appConfig.DETAIL_LOAD_STATUS.incomplete
                ) {
                  result.status = "diff";
                } else if (
                  row.packStatus == appConfig.DETAIL_PACK_STATUS.delay ||
                  row.loadStatus == appConfig.DETAIL_LOAD_STATUS.delay
                ) {
                  result.status = "error";
                } else if (
                  row.packStatus == appConfig.DETAIL_PACK_STATUS.warning ||
                  row.loadStatus == appConfig.DETAIL_LOAD_STATUS.warning
                ) {
                  result.status = "warning";
                } else {
                  result.status = "normal";
                }
                return result;
              });
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
            }

            resolve(response);
          })
          .catch((resoleve) => {
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            reject(resoleve);
          })
          .finally(() => {
            this.loadingCounter--;
          });
      });
    },
    /**
     * 表示切替を行った際のイベントです。
     */
    changeToggle() {
      console.debug("changeToggle--start--");
      screenAuth.canAccess();
      this.getProgressDetailList()
        .then(() => {})
        .catch(() => {})
        .finally(() => {});
    },
    /**
     * 更新ボタンのイベントです。
     */
    update() {
      console.debug("update--start--");
      screenAuth.canAccess();
      this.getProgressDetailList()
        .then(() => {})
        .catch(() => {})
        .finally(() => {});
    },
    /**
     * 積付完了済ボタン
     */
    packCompletion() {
      console.debug("packCompletion");
      screenAuth.canAccess();
      this.loadingCounter++;
      this.callProgressDetailUpdate(appConfig.DETAIL_PROCESS_TYPE.packCompletion)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          if (jsonData.resCom.resComCode == "000") {
            this.getProgressDetailList();
            this.infoDialog.message = messsageUtil.getMessage("P-MNG-002_002_C");
          } else {
            this.infoDialog.message = jsonData.resCom.resComMessageId
              ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
              : jsonData.resCom.resComMessage;
          }
          this.infoDialog.title = "結果";
          this.infoDialog.isOpen = true;
        })
        .catch((error) => {
          console.error("packCompletion error", error);
          this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
        })
        .finally(() => {
          this.loadingCounter--;
        });
    },
    /**
     * 積込完了済ボタン
     */
    loadCompletion() {
      console.debug("loadCompletion");
      screenAuth.canAccess();
      this.loadingCounter++;
      this.callProgressDetailUpdate(appConfig.DETAIL_PROCESS_TYPE.loadCompletion)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          if (jsonData.resCom.resComCode == "000") {
            this.getProgressDetailList();
            this.infoDialog.message = messsageUtil.getMessage("P-MNG-002_002_C");
          } else {
            this.infoDialog.message = jsonData.resCom.resComMessageId
              ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
              : jsonData.resCom.resComMessage;
          }
          this.infoDialog.title = "結果";
          this.infoDialog.isOpen = true;
        })
        .catch((error) => {
          console.error("loadCompletion error", error);
          this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
        })
        .finally(() => {
          this.loadingCounter--;
        });
    },
    /**
     * 積付期限更新ボタン
     */
    packDeadlineUpdate() {
      console.debug("packDeadlineUpdate");
      screenAuth.canAccess();
      this.loadingCounter++;
      this.callProgressDetailUpdate(appConfig.DETAIL_PROCESS_TYPE.packDeadlineUpdate)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));

          if (jsonData.resCom.resComCode == "000") {
            this.getProgressDetailList();
            this.infoDialog.message = messsageUtil.getMessage("P-MNG-002_002_C");
          } else {
            this.infoDialog.message = jsonData.resCom.resComMessageId
              ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
              : jsonData.resCom.resComMessage;
          }
          this.infoDialog.title = "結果";
          this.infoDialog.isOpen = true;
        })
        .catch((error) => {
          console.error("packDeadlineUpdate error", error);
          this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
        })
        .finally(() => {
          this.loadingCounter--;
        });
    },
    /**
     * 積込期限更新ボタン
     */
    loadDeadlineUpdate() {
      console.debug("loadDeadlineUpdate");
      screenAuth.canAccess();
      this.loadingCounter++;
      this.callProgressDetailUpdate(appConfig.DETAIL_PROCESS_TYPE.loadDeadlineUpdate)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));

          if (jsonData.resCom.resComCode == "000") {
            this.getProgressDetailList();
            this.infoDialog.message = messsageUtil.getMessage("P-MNG-002_002_C");
          } else {
            this.infoDialog.message = jsonData.resCom.resComMessageId
              ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
              : jsonData.resCom.resComMessage;
          }
          this.infoDialog.title = "結果";
          this.infoDialog.isOpen = true;
        })
        .catch((error) => {
          console.error("loadDeadlineUpdate error", error);
          this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
        })
        .finally(() => {
          this.loadingCounter--;
        });
    },
    /**
     * 搬入指示データ更新APIに、呼び出し元のprocessTypeで、
     * テーブルでチェックされたデータ分送信します。
     */
    callProgressDetailUpdate(processType) {
      console.debug("callProgressDetailUpdate--start--");
      screenAuth.canAccess();
      const body = this.$httpClient.createRequestBodyConfig();

      // テーブルからチェックされているデータのみ取り出して連想配列に詰めます。
      const deliverySlipNo = this.progressDetailsList
        .filter((row) => {
          if (row.check) {
            return row;
          }
        })
        .map((row) => {
          const data = {};
          data.deliverySlipNo = row.deliverySlipNo;
          data.deliverySlipSeq = row.deliverySlipSeq;
          data.processType = processType;
          if (
            processType == appConfig.DETAIL_PROCESS_TYPE.packDeadlineUpdate ||
            processType == appConfig.DETAIL_PROCESS_TYPE.loadDeadlineUpdate
          ) {
            data.limitHour = this.deadlineTime;
          }
          data.lastUpdateDatetime = dateTimeHelper.toStringDate(
            "YYYY/MM/DD hh:mm:ss.SSS",
            new Date(row.updateDatetime)
          );

          return data;
        });
      body.reqIdv.revDeliverySlipNo = deliverySlipNo;

      return new Promise((resolve, reject) => {
        this.$httpClient
          .securePost(appConfig.API_URL.deliveryrequests, body, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == "000") {
              this.infoDialog.message = messsageUtil.getMessage("P-MNG-002_002_C");
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessage;
            }
            this.infoDialog.title = "結果";
            this.infoDialog.isOpen = true;
            resolve(response);
          })
          .catch((resoleve) => {
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            reject(resoleve);
          })
          .finally(() => {});
      });
    },
    formValidation() {
      this.$refs.deadlineTime.validate();
    },
  },
  computed: {
    /**
     * 搬入日時を構成します。
     */
    deliveryDateTime() {
      return this.deliveryDateStr.replace(/-/g, "/") + " " + this.deliveryTime;
    },
    /**
     * テーブルのデータにチェックがされているかどうかを返します。
     */
    isDataTableChecked() {
      const count = this.progressDetailsList.reduce((prev, current) => {
        if (current.check) {
          return prev + 1;
        }
        return prev;
      }, 0);
      return count > 0;
    },
    /**
     * 機能の読み取り権限を返します。
     */
    canReadAuth() {
      const role = this.roles.split(",")[0];
      let canScreen = functionAuth.canFunctionAuth(role, appConfig.FUNCTION_ID.P_MNG_002_READ);

      return canScreen;
    },
    /**
     * 機能の更新権限を返します。
     */
    canUpdateAuth() {
      const role = this.roles.split(",")[0];
      let canScreen = functionAuth.canFunctionAuth(role, appConfig.FUNCTION_ID.P_MNG_002_UPDATE);

      return canScreen;
    },
    /**
     * 自営業所かどうかを返します。
     */
    isBaseCodeChecked() {
      const salesOfficeCodeChecked = sessionStorage.getItem("sales_office_code");
      console.log("", this.entity.selectSalesOfficeCode);
      console.log("", salesOfficeCodeChecked);
      if (this.entity.selectSalesOfficeCode == salesOfficeCodeChecked) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    isOpenDetailDialog: function (newValue, oldValue) {
      console.info("ProgressDetail");
      if (newValue) {
        this.init();
      }
    },
  },
  mounted() {
    this.roles = sessionStorage.getItem("role");
    this.init();
  },
};
</script>

<style lang="scss" scoped>
#sub-bar {
  color: black;
  background: $color-obj-base;
  padding: 0;
  height: 3rem;
  #lbl-screen-name {
    padding: 0.1rem 0 0 0.5rem;
    font-size: x-large;
    font-weight: bold;
    display: inline-block;
  }
  .right {
    text-align: right;
  }
  #btn-close {
    margin: 0.3rem;
    padding: 0;
    height: 1.7rem;
    width: 1.7rem;
    min-width: 1rem;
    background-color: #979797;
    img {
      height: 1rem;
      width: 1rem;
    }
  }
}
.v-btn.v-btn--has-bg {
  background: $color-obj-base;
  &.api-btn {
    width: 7rem;
  }
}
#display-type {
  width: 25rem;
  height: 5.5rem;
  .v-card__title {
    font-size: 1rem;
    padding: 0 0.2rem;
  }
  .toggle {
    padding: 0.3rem 2rem;
    .btn {
      width: 5rem;
      height: 2.5rem;
    }
  }
}
#table-guide {
  margin-top: 4.5rem;
  font-size: middle;
  #page-count-guide {
    width: 100%;
    text-align: center;
  }
  .guide-space {
    padding: 0;
  }
  #guide-color {
    margin-top: 1rem;
    .guide-diff,
    .guide-warning,
    .guide-error {
      display: inline-block;
      width: 6rem;
      text-align: center;
      border: solid 1px;
      margin: 0.2rem 0.5rem;
    }
    .guide-diff {
      background: $color-diff;
    }
    .guide-warning {
      background: $color-warning;
    }
    .guide-error {
      background: $color-error;
    }
  }
}
.search-label {
  width: 100%;
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0;
  text-align: right;
  font-size: large;
  color: rgba(0, 0, 0, 0.6);
}
.txt-single ::v-deep {
  padding-top: 0;
  padding-right: 0;
}
#detail-table {
  &.v-data-table--fixed-header ::v-deep {
    table {
      //border-collapse: collapse !important;
      border-collapse: separate !important;
      border-spacing: 0;
    }
    td,
    th {
      font-size: 90% !important;
      &:not(:nth-child(1)) {
        padding: 1 0.5rem 0 0 !important;
      }
    }
    td {
      div.check-box {
        margin: auto;
        text-align: center;
        .v-input__slot {
          display: inline-block;
        }
      }
      &.center {
        text-align: center;
      }
      &.right {
        text-align: right;
      }
      &.left {
        text-align: left;
      }
      &:not(:nth-child(-n + 3)) {
        //border-top: 1px solid $border-color;
        border-right: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        //border-left: 1px solid $border-color;
      }
      img.detail-icon {
        width: 20px;
        height: 20px;
      }
    }
    tr {
      &.diff {
        background-color: $color-diff !important;
        td:nth-child(1),
        td:nth-child(2),
        td:nth-child(3) {
          background-color: $color-diff !important;
        }
      }
      &.warning {
        background-color: $color-warning !important;
        td:nth-child(1),
        td:nth-child(2),
        td:nth-child(3) {
          background-color: $color-warning !important;
        }
      }
      &.error {
        background-color: $color-error !important;
        td:nth-child(1),
        td:nth-child(2),
        td:nth-child(3) {
          background-color: $color-error !important;
        }
      }
    }
    tr:nth-child(odd) {
      background-color: #dde5f0;
      td {
        &:nth-child(1) {
          background: #eef1de;
        }
      }
    }
    th {
      padding-left: 0.1rem;
      padding-right: 0.1rem;
      background-color: #fbe9db;
      &.warning {
        background-color: $color-warning !important;
      }
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        position: sticky !important;
        z-index: 31;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-top: 1px solid $border-color;
          //border-right: 1px solid $border-color;
          border-bottom: 1px solid $border-color;
        }
      }
      &:nth-child(1) {
        &::before {
          border-left: 1px solid $border-color;
        }
      }
      &:nth-child(2),
      &:nth-child(3) {
        &::before {
          border-right: 1px solid $border-color;
        }
      }
      &:not(:nth-child(-n + 3)) {
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-top: 1px solid $border-color;
          border-right: 1px solid $border-color;
          border-bottom: 1px solid $border-color;
          //border-left: 1px solid $border-color;
        }
      }
      &:nth-child(1) {
        min-width: 4rem !important;
        border-right: 0;
      }
      &:nth-child(2) {
        left: 4rem;
        min-width: 10rem !important;
        border-left: 0;
      }
      &:nth-child(3) {
        left: 14rem;
        min-width: 8rem !important;
      }
    }
    td:nth-child(1),
    td:nth-child(2),
    td:nth-child(3) {
      position: sticky !important;
      z-index: 30;
      background-color: #eef1de;
      border: none;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        //border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        //border-right: 1px solid $border-color;
        border-left: 1px solid $border-color;
      }
    }
    td:nth-child(1) {
      left: 0;
    }
    td:nth-child(2) {
      left: 4rem;
      text-align: left !important;
    }
    td:nth-child(3) {
      left: 14rem;
      &::before {
        border-right: 1px solid $border-color;
      }
    }
  }
}
#footer-navi {
  margin-top: 0;
}
</style>
